import { connect, MapStateToProps } from 'react-redux';
import { isBannerVisible } from '@wix/da-shared-react/pkg/redux/sisuFunnel/selectors';
import { AppState } from '../../types/store';
import {
  getCurrentPageType,
  getSearchPath,
  getSearchQuery,
} from '../../selectors/page';
import {
  getShowNetworkBarInSiteHeader,
  getSelectedNetworkBarItem,
} from '../../selectors/app';
import { Props } from './App';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import { isPWA } from '@wix/da-shared-react/pkg/utils/isPWA';

type StateProps = Pick<
  Props,
  | 'environment'
  | 'sisuFunnelBannerVisible'
  | 'showNetworkBarInSiteHeader'
  | 'activeSiteHeaderItem'
  | 'searchTerm'
  | 'searchPath'
  | 'pageType'
>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  environment: getEnv(state).type,
  sisuFunnelBannerVisible: isBannerVisible(state),
  PWABannerVisible: isPWA(), // feature protected by "pwa_banners"
  showNetworkBarInSiteHeader: getShowNetworkBarInSiteHeader(state),
  activeSiteHeaderItem: getSelectedNetworkBarItem(state) as string,
  searchTerm: getSearchQuery(state),
  searchPath: getSearchPath(state),
  pageType: getCurrentPageType(state),
});

export default connect(mapStateToProps);
